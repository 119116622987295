import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import BlobClip from "../assets/clipBlob.inline.svg"
import Blob from "../assets/blob.inline.svg"
import * as webStyles from "../styles/webudvikling.module.css"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import SwipeDown from "../assets/swipe_down_anim.inline.svg"
import ScrollDown from "../assets/scroll_down_anim.inline.svg"
import { StaticImage } from "gatsby-plugin-image"
import NextArrow from "../components/nextArrow"
import PreviousArrow from "../components/previousArrow"
import LanguageSwitch from "../components/languageSwitch"
import ThemeSwitch from "../components/themeSwitch"
import { InstallPWA } from "../components/installPWA"
import Seo from "../components/seo"
import { gsap } from "gsap/dist/gsap"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import SoundButton from "../components/soundToggle"
import Page03 from "../assets/page03.inline.svg"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion"
import {
  floating,
  swipeDown,
  scrollDownMouse,
} from "../components/GSAPanimations"
import useDeviceDetect from "../components/deviceDetect"

const Webudvikling = ({ location }) => {
  const { language } = useI18next()
  const prefersReducedMotion = usePrefersReducedMotion()
  let skillsURL
  let jamStackURL
  let PWAURL

  if (language === "da") {
    skillsURL = "/kompetencer/"
    // jamStackURL = "/artikler/JAMstack-spraenger-rammerne-for-webudvikling/"
    // PWAURL = "/artikler/derfor-bor-din-naeste-hjemmeside-vaere-en-progressive-web-app/"
    jamStackURL = "/artikler/artikler-kommer-snart/"
    PWAURL = "/artikler/artikler-kommer-snart/"
  } else {
    skillsURL = `/${language}/kompetencer/`
    // jamStackURL = `/${language}/artikler/JAMstack-spraenger-rammerne-for-webudvikling/`
    // PWAURL = `/${language}/artikler/derfor-bor-din-naeste-hjemmeside-vaere-en-progressive-web-app/`
    jamStackURL = `/${language}/artikler/artikler-kommer-snart/`
    PWAURL = `/${language}/artikler/artikler-kommer-snart/`
  }

  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    let scrollStorage = window.localStorage.getItem("scroll")
    if (scrollStorage === null) {
      scrollStorage = 1
    } else if (scrollStorage < 20) {
      scrollStorage = JSON.parse(scrollStorage) + 1
    }
    window.localStorage.setItem("scroll", scrollStorage)
    const hand1 = document.querySelector("#hand1")
    const arrow1 = document.querySelector("#arrow1")
    const mouse = document.querySelector("#mouse")
    const scrollWheel = document.querySelector("#scrollWheel")
    const arrow2 = document.querySelector("#arrow2")
    const swipeDownAnim = document.querySelector("#swipeDownAnim")
    const ScrollDownAnim = document.querySelector("#ScrollDownAnim")
    if (isMobile === undefined) return
    if (isMobile && scrollStorage < 6) {
      swipeDown(hand1, arrow1, swipeDownAnim)
    } else if (scrollStorage < 6) {
      scrollDownMouse(mouse, scrollWheel, arrow2, ScrollDownAnim)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const blob2 = document.querySelector("[class*='blob2']")
    const blob3 = document.querySelector("[class*='pwa']")
    const blob4 = document.querySelector("[class*='blob4']")
    const pageNum = document.querySelector("[class*='pageNum']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    const dot2 = document.querySelector("[class*='dotPattern2']")
    gsap.killTweensOf(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
    if (prefersReducedMotion === true) {
      return
    }
    floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
  }, [prefersReducedMotion]) // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation()

  return (
    <>
      <Seo
        title={t("WEB.SEO_TITLE")}
        description={t("WEB.SEO_DESCRIPTION")}
        pathName={location.pathname}
        image="SoMe/open_graph_ejaas_pwa.png"
        readingTime="2"
      />
      <PreviousArrow
        to={language === "da" ? `/portfolio/` : `/${language}/portfolio/`}
      />
      <NextArrow to={language === "da" ? `/grafik/` : `/${language}/grafik/`} />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <SwipeDown />
        <ScrollDown />
        <section className="card">
          <Page03 className={webStyles.pageNumber} />
          <h1 className={webStyles.Display1}>{t("WEB.DISPLAY")}</h1>
          <h2>{t("WEB.HEADLINE")}</h2>
          <span>
            <p className="pre">{t("WEB.BODY_TEXT")}</p>
            <ListTLink
              style={{ whiteSpace: "pre" }}
              label={t("KOMPETENCER.DISPLAY")}
              to={skillsURL}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("WEB.SEE_MY_SKILLS_HERE")}
            </ListTLink>
            <p className="pre">.</p>
          </span>
          <BlobGradient className={webStyles.blob1} />
          <Dotpattern className={webStyles.dotPattern1} />
        </section>
        <section className="card right" style={{ paddingBottom: "0" }}>
          <Blob className={webStyles.blob2} />
          <BlobClip className={webStyles.blob3} />
          <div className={webStyles.pwa}>
            <StaticImage
              Tag="section"
              alt="Progressive web App"
              src="../images/PWA_isometric.png"
              placeholder="blurred"
              layout="constrained"
              width={812}
              loading={isMobile ? "lazy" : "eager"}
              style={{
                display: "block",
                maxWidth: "812px",
                zIndex: 10,
                margin: "0 auto",
              }}
            />
          </div>
          <Blob className={webStyles.blob4} />
          <Dotpattern className={webStyles.dotPattern2} />
        </section>
        <section
          className="card wide"
          style={{ paddingTop: "0", overflow: "visible" }}
        >
          <h2>{t("WEB.HEADLINE_JAMSTACK")}</h2>
          <span>
            <p className="pre">{t("WEB.JAMSTACK_BODY")}</p>
            <ListTLink
              style={{ whiteSpace: "pre" }}
              label={t("WEB.JAMSTACK_ARTICLE_URL_TITLE")}
              to={jamStackURL}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("WEB.READ_JAMSTACK_ARTICLE")}
            </ListTLink>
            <p className="pre">{t("WEB.JAMSTACK_BODY_TWO")}</p>
            <a
              className="pre"
              href="https://jamstack.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("WEB.OFFICIAL_JAMSTACK_PAGE")}
            </a>
            <p className="pre">{t("WEB.JAMSTACK_BODY_THREE")}</p>
            <a
              className="pre"
              href="https://reactjs.org/"
              aria-label={t("WEB.OFFICIAL_REACT_PAGE_TITLE")}
              title={t("WEB.OFFICIAL_REACT_PAGE_TITLE")}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("WEB.OFFICIAL_REACT_PAGE")}
            </a>
            <p className="pre">{t("WEB.JAMSTACK_BODY_FOUR")}</p>
            <a
              className="pre"
              href="https://www.gatsbyjs.com/"
              aria-label={t("WEB.OFFICIAL_GATSBY_PAGE")}
              title={t("WEB.OFFICIAL_GATSBY_PAGE_TITLE")}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("WEB.OFFICIAL_GATSBY_PAGE")}
            </a>
            <p className="pre">{t("WEB.JAMSTACK_BODY_FIVE")}</p>
            <a
              className="pre"
              href="https://www.netlify.com/"
              aria-label={t("WEB.NETLIFY_PAGE_TITLE")}
              title={t("WEB.NETLIFY_PAGE_TITLE")}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("WEB.NETLIFY_PAGE")}
            </a>
            <p className="pre">.</p>
          </span>
          <h2>{t("WEB.HEADLINE_PWA")}</h2>
          <span>
            <p className="pre">{t("WEB.PWA_BODY")}</p>
            <ListTLink
              style={{ whiteSpace: "pre" }}
              label={t("WEB.PWA_ARTICLE_URL_TITLE")}
              to={PWAURL}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("WEB.PWA_ARTICLE")}
            </ListTLink>
            <p className="pre">.</p>
          </span>
          <p>{t("WEB.PWA_BODY_TWO")}</p>
          <InstallPWA />
          <h2>{t("WEB.HEADLINE_ACCESSIBILITY")}</h2>
          <span>
            <p className="pre">{t("WEB.ACCESSIBILITY_BODY")}</p>
            <a
              className="pre"
              href="https://developer.paciellogroup.com/blog/2019/05/short-note-on-prefers-reduced-motion-and-puzzled-windows-users/"
              aria-label={t("WEB.PACIELLOGROUP_TITLE")}
              title={t("WEB.PACIELLOGROUP_TITLE")}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("WEB.PACIELLOGROUP_PAGE")}
            </a>
            <p className="pre">{t("WEB.ACCESSIBILITY_BODY_TWO")}</p>
          </span>
        </section>
      </main>
    </>
  )
}

export default Webudvikling

export const queryData = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
