import React, { useContext } from "react"
import Chevron from "../assets/chevron.inline.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { ListTLink, transitionFadeOut, transitionFadeIn } from "./layout.js"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const NextArrow = props => {
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const { t } = useTranslation()
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  return (
    <nav className="next">
      <ListTLink
        to={props.to}
        exit={{
          length: 0.4,
          trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
        }}
        entry={{
          delay: 0.4,
          appearAfter: 0.4,
          length: 0.4,
          trigger: ({ entry, node }) => transitionFadeIn(node),
        }}
        label={t("NEXT_ARROW")}
      >
        <button
          tabindex="-1"
          onClick={play}
          className="nextArrow"
          aria-label={t("NEXT_ARROW")}
          title={t("NEXT_ARROW")}
        >
          <Chevron className="chevronNext" />
        </button>
      </ListTLink>
    </nav>
  )
}

export default NextArrow
